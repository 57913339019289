import { useState, useEffect } from "react";

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

export const useIsMobile = () => useMediaQuery("(max-width: 767px)");
export const useIsTablet = () => useMediaQuery("(max-width: 1023px)");

export const useBackofficeURL = () =>
  process.env.GATSBY_BACKOFFICE_PAGE || "https://backoffice.wealthx.au";

export const useDemoPageURL = () =>
  process.env.GATSBY_DEMO_PAGE || "https://demo.app.wealthx.au";
