import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { LogoDark } from "../../assets/images";
import { useBackofficeURL, useIsTablet } from "../../hooks/common.hooks";
import "./navigation-bar.scss";

type navigationLogoProps = { src: string };

const navigationItem = [
  { title: "Features", href: "/features" },
  { title: "Pricing", href: "/pricing" },
  { title: "Contact", href: "/contact" },
  {
    title: "Login",
    href: `${process.env.GATSBY_BACKOFFICE_PAGE}/login`,
  },
];

const handleNavigateSignupPage = (url: string) => {
  window.location.href = url;
};

const NavigationLogo = ({ src }: navigationLogoProps) => (
  <img src={src} alt="" />
);

const NavigationBar = () => {
  const backOfficeURL = useBackofficeURL();
  const signupPage = `${backOfficeURL}/signup`;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isTablet = useIsTablet();
  const wrapperRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside({ target }: MouseEvent): void {
      if (
        wrapperRef.current &&
        !wrapperRef?.current?.contains(target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickMenuItem = (href: string) => {
    setIsMenuOpen(false);
    navigate(href);
  };
  return (
    <div className="navigation-bar" ref={wrapperRef}>
      <div className="navigation-bar__container">
        <div
          className="navigation-bar__icon"
          onClick={() => {
            handleClickMenuItem("/");
          }}
        >
          <NavigationLogo src={LogoDark} />
        </div>
        {!isTablet ? (
          <div className="navigation-bar__items">
            {navigationItem.map(({ title, href }) => (
              <div
                key={title}
                className="navigation-bar-items__item"
                onClick={() => {
                  handleClickMenuItem(href);
                }}
              >
                {title}
              </div>
            ))}
            <button
              type="button"
              className="navigation-bar__button"
              onClick={() => handleNavigateSignupPage(signupPage)}
            >
              Sign-up
            </button>
          </div>
        ) : (
          <div className="hamburger-button" onClick={handleOpenMenu}>
            <div className={isMenuOpen ? "open" : ""} id="nav-icon2">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        )}
      </div>
      <div
        ref={wrapperRef}
        className={classNames("navigation-bar__menu", { open: isMenuOpen })}
      >
        {navigationItem.map(({ title, href }) => (
          <div
            key={title}
            className="navigation-bar-items__item"
            onClick={() => {
              handleClickMenuItem(href);
            }}
          >
            {title}
          </div>
        ))}
        <button
          type="button"
          className="navigation-bar__button"
          onClick={() => handleNavigateSignupPage(signupPage)}
        >
          Sign-up
        </button>
      </div>
    </div>
  );
};

export default NavigationBar;
