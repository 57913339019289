import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../footer";
import NavigationBar from "../navigation-bar";
import "./layout.scss";
import "../../assets/scss/variables.css";
import FavIcon from "../../favicon.svg";

type Props = {
  children?: React.ReactNode;
};

const Layout = ({ children }: Props) => (
  <>
    <Helmet
      defaultTitle="WealthX"
      link={[{ rel: "shortcut icon", type: "image/png", href: `${FavIcon}` }]}
      meta={[{ name: "format-detection", content: "telephone=no" }]}
    />
    <NavigationBar />
    {children}
    <Footer />
  </>
);

export default Layout;
